import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesLaplace: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Laplace (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_laplace_tr.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Laplace (Treasure) guide & review</h1>
          <h2>
            A guide & review for Laplace (Treasure) in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>22/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Laplace (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon-tier"
                  slug="laplace"
                  enablePopover
                  tierID={54}
                />
              </div>
              <p>
                It has been a very long time since we first got our hands on the
                one and only: Hero of the Ark, Leader of Matis, and the favorite
                of Syuen, Laplace! When she first came, the world of Nikkes was
                new to us. For a lot of us, she was indeed the very hero
                portrayed in Chapter 18, as she helped us clear campaign, beat
                Grave Digger in Interception S, and more. However, as time
                progressed, we saw more units being released, and our Hero saw
                less and less use, eventually being pushed out of the meta
                completely. 1 year, 6 months, and 6 days later, she has her say
                yet again. It's fair to say that her fans have waited an
                eternity for this day. Now let's take a look together at how
                much she has improved thanks to her favorite item!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Laplace cements herself currently in the top 10 DPS units! She
                will definitely have her say against Iron Weak Bosses from now
                on. She may also be used against other bosses, but it depends on
                whether pierceable parts exist. The point to note here is that
                her laser pierces and, hence, increases her damage substantially
                when there are more parts to hit. If there are no pierceable
                spots, Laplace can lose her spot to other DPS units if the
                element weakness is not in her favor.
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                <strong>YES!</strong> She should be the{' '}
                <strong>top priority</strong> to upgrade in Season 1 of Favorite
                Items. In Laplace lies a new usable Meta Unit in all game modes
                (yes, even in PvP!). While it does take time to collect
                materials and upgrade in this new system, we recommend getting
                to Phase 1 for the added Burst Gen she provides in PvP, Phase 2
                for her DPS to be competitive enough to matter, and Phase 3 to
                further improve her DPS! If you are aiming to go for other
                treasures as well, we recommend getting Laplace to at least
                Phase 2 on her favorite item.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/laplace_tr.webp"
                alt="Kit"
              />
              <h5>Laplace the PvP Gamer [Phase 1]</h5>
              <p>
                In Phase 1, Laplace's Skill 2 transforms from additional damage
                being a last bullet effect into one that requires full-charged
                shots to activate. While this is a decent buff for her damage in
                PvE content, it is even better for PvP content. This extra tick
                of damage will now proc whenever Laplace hits a shot on
                opponents and provide the team with extra Burst Generation! If
                you know PvP well, you can understand how important Burst
                Generation is! With Treasure, Laplace Burst Gen Rank increases
                to the #6 spot, just below Clip SGs! Finally, since this new
                mechanic is based on fully charging Laplace's shots, she can be
                considered as Auto-Friendly. However, in PvE, you may play her
                like Trony, with a mix of fully charged shots as well as Quick
                Scopes to maximize her potential. Remember to have Skill 1
                always fully stacked!
              </p>
              <h5>The Hero Arrives! [Phase 2]</h5>
              <p>
                Phase 2 is where Laplace gets her PvE breakthrough. The standout
                changes are to Laplace's laser, which is her weapon change
                during Burst. It now lasts for 10 seconds instead of the old 5
                seconds! This is an immense buff to her damage since her laser
                is the majority of her DPS. This is the buff that she has been
                waiting for. Something that, from what we remember, was taken
                away from her just before she was released to the public all
                those months ago! But that's not all. The second change to her
                Burst further improves her DPS. An additional effect now occurs
                when Laplace enters her Burst with her S1 at full stacks, which
                is that all of her damage from the laser is converted into True
                Damage! All of this combines to help Laplace regain her standing
                in the current Meta. While she is no Red Hood, she is now a
                worthy competitor to many DPS units, such as Scarlet or Summer
                Anis.
              </p>
              <Alert variant="primary">
                <p>
                  It is worthy to note that if Laplace's Skill 1 is fully
                  stacked before entering her own Burst, all the damage ticks
                  from skills and laser during her own Burst will be True
                  Damage. True Damage is damage that completely ignores the DEF
                  values of the opponent (i.e., considers the DEF value of the
                  opponent as 0). If Laplace loses her S1 stacks during Burst,
                  her True Damage effects will remain. The only thing that stops
                  proccing is the extra 11.9% damage per tick, which is directly
                  based on her Skill 1 stacks and needs Phase 3 treasure for
                  maximum uptime.
                </p>
              </Alert>
              <h5>Hero Binoculars [Phase 3]</h5>
              <p>
                In Phase 3, Laplace extends the duration of Skill 1's "Hero
                Vision," which now lasts 15s instead of the old 5s. This
                increases the uptime of her Range Buff, but more importantly, it
                increases the duration of the True Damage tick by 5 seconds.
                Since Laplace shoots at an increased fire rate with her laser
                during her own Burst, this is an upgrade to her damage during
                Burst. While not a substantial buff, it is a decent 1100%+ of
                True Damage on a single opponent, which is multiplied along with
                the number of parts Laplace hits!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Important
                  findings about Laplace
                </h6>
                <p>
                  It is very important to understand some things about Laplace.
                  Currently, there is misinformation among the community that
                  Laplace is bugged. However, this is NOT the case.{' '}
                  <strong>Laplace is NOT bugged</strong>. The misinformation is
                  present due to visual bugs, which do not show the Part Damage
                  Numbers that are a part of Laplace's Skill 2, and only show
                  the True Damage numbers. Very rarely, you may see these Part
                  Damage ticks appear. However, the most solid proof of this is
                  comparing her damage with and without Skill 1 fully stacked
                  against a boss with parts from damage charts (which you can
                  see when you pause the game). Back-tracking calculations show
                  that Laplace Skill 2 works normally!
                </p>
              </blockquote>
              <h5>
                Campaign (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Laplace can do some serious damage, and the best part is that
                she can only get better the higher the deficit goes. The main
                drawback here about Laplace is that she cannot cover during her
                own Burst. This is a critical point since a lot of times there
                are one-shot attacks that you need to cover from to survive.
                Moreso, while she can deal good damage but cannot compete with
                the likes of Alice or Modernia, until she is either on element
                or unless you are in ultra-high deficits, which are now
                extremely harder to reach due to the new update with Overload
                effects inflating Combat Power. Regardless, while Laplace may
                not be the best choice for every situation, she is a strong
                contendor to be used in various situations.
              </p>
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Laplace can perform extremely well in Low Deficits. Previously,
                lower Deficits were defined to be a walk in the park if you had
                certain units. Now, however, 25–30% deficits can prove to be
                extremely tough conditions, similar to what 35-37% deficits used
                to be before the CP update. Regardless, just like in High
                Deficit, Laplace remains a strong contender for the top spot in
                Campaign! Expect a change in definitions for Low Deficit and
                High Deficit campaign on the website soon!
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong>
              </h5>
              <p>
                This is where Laplace should shine the most! Raids are the
                cornerstone of measuring how well a Nikke performs compared to
                other units. Laplace performs at a very good level against most
                Tyrant Class Bosses! What makes her good is what makes Red Hood,
                Alice, Snow White and Maxwell good: Pierce! Laplace's usability
                can vary sharply according to the stage situation against Solo
                Bosses, some of which can be found as follows:
              </p>
              <p>
                Firstly, she loves to go up against Iron Weak Bosses! Make sure
                to have those Elemental Damage rolls on her OL gear for this! In
                this case, she should easily be able to overtake the likes of
                even Modernia in damage! However, on neutral grounds, Modernia
                should be able to defeat Laplace whenever there are no
                pierceable parts. When there is a core available, Modernia's
                damage skyrockets, at which point Laplace needs pierceable parts
                to come near her damage. When there is one pierce available,
                Laplace should be able to compete with Modernia in terms of
                damage. In cases where Laplace can pierce two or more parts
                together (such as against Heavy Metal or Ultra), Laplace pulls
                away ahead!
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/laplce_tr_1.webp"
                alt="Kit"
              />
              <p>
                In other comparisons with notable units like Scarlet or S.Anis,
                Laplace can do similar damage to both of them, if not more.
                Whenever there are pierceable parts, Laplace will simply do more
                damage than them, unless the element favors Scarlet and S.Anis.
                Another unique comparison can be made to Scarlet: Black Shadow.
                If SBS is not paired with Alice, Laplace piercing just 1 part +
                body can allow her to reach equal damage to SBS! Nevertheless,
                SBS pulls ahead if she has Alice with her. In all these cases,
                however, Laplace can do more damage comparatively if the element
                favors her and she has at least 1 pierce available!
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/laplce_tr_2.webp"
                alt="Kit"
              />
              <h5>
                Boss (Adds) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Bosses with additional raptures are less likely to have
                additional parts to pierce in Nikke. Adding to that, the stage
                designs are also more likely to be similar to campaign, where
                the competition to fit in the 5 slots of a team is a lot tougher
                compared to 3-5 teams of Raids or PvP. However, Laplace is still
                an excellent choice for these stages since she can handle small
                raptures with quite ease and can also deal good damage to the
                boss.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                While Laplace is not the best Nikke to Burst with, with this new
                treasure update, she is one of the best Nikkes to provide Burst
                Generation to the team! The extra-damage tick is also excellent
                for pressuring P1 and P2 units. Hence, due to this extra damage
                as well as the extra Burst Gen, Laplace is now considered an
                excellent PvP unit.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug="laplace"
                        mode="icon-tier"
                        showLabel
                        enablePopover
                        onlyName
                        tierID={54}
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      <p>-</p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      <p>
                        <strong>4× Elemental Damage</strong>
                      </p>
                      <p>
                        <strong>4× ATK</strong>
                      </p>
                      <p>
                        <strong>1-2× Max Ammo</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      <p>
                        <strong>4× Charge Speed</strong>
                      </p>
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      <p className={`prio prio-10`}>High</p>
                    </div>
                  </div>
                  <div className={`explanation`}>
                    <p className="on-mobile">Notes</p>
                    <p>
                      Elemental Damage and ATK are the basic food of any good
                      DPS in Nikke. 1-2× Max Ammo are good to reduce the number
                      of Reloads Laplace does. Meanwhile, if you manage to
                      obtain 21%+ Charge Speed through Overload Gear, she can
                      provide extremely high Burst Generation for your team in
                      PvP. This can possibly increase her rank in the Burst
                      Generation up to the Top 5, right above Clip Shotguns!
                    </p>
                  </div>
                </div>
              </div>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 1~4</strong>
                </li>
                <ul>
                  <li>
                    While this skill is essential to Laplace's kit, this is the
                    most useless part of her kit in terms of value for
                    upgrading. You can leave this at lvl 1 if you like, or
                    upgrade it to lvl 4 for CP padding.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    The new change of Skill 2's additional damage now being Full
                    Charged based makes it more worth it to upgrade this skill.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 10</strong>
                </li>
                <ul>
                  <li>
                    With the new laser duration, there is no question about it.
                    Laplace Burst needs to be maxed out to maximize her
                    potential DPS.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="laplace" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                Resilience Cube should be the best option for Laplace in PvE
                content. In PvP, she can make good use of the Charge Speed Cube
                as well.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The King and Her Subjects</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                General Campaign team. This team should strike fear in the
                hearts of any Electric Element Rapture! Once you have Elemental
                Damage rolls on Laplace's OL gear, she can be used to good
                effect on Iron Weak stages. To make this a team for Bosses, you
                can swap out Naga for S.Helm, and instead strike terror in the
                hearts of any Electric Element Boss (since they are weak to
                Iron).
              </p>
              <h5>Team #2: Schoolgirls meet the Hero!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Laplace is an RL, so her accuracy is absolutely pinpoint. Hence,
                even at far range she is able to core hit extremely effectively,
                just like an MG or SR unit! Hence, using her with Tia Naga is
                highly effective!
              </p>
              <h5>Team #3: A good RL unit? Rem approves!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                Laplace lacks innate ATK buffs. As a result, she scales
                extremely well with them! Hence, Rem and Liter's ATK buffs are
                of high value to Laplace. Not only that, Rem can heal Laplace
                very effectively, and allows. Now all that is left is for
                Shiftup to fill in the other 2 spots for this team.
              </p>
              <h5>Team #4: True Damage Team(?)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="frima" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team is incomplete at the moment due to their being only
                one DPS unit that does true damage. Hence, we need one more B3
                unit to fill in the remaining spot. The flex spot can most
                probably be a CDR unit, such as Dolla or Summer Helm. Maybe in
                the future Shift Up will decide to release a True Damage DPS B3
                unit, this team can be extremely relevant in terms of what we
                consider Meta for Boss Raids. Regardless, this team requires
                immense investment as it needs both Laplace Treasure at Phase 2
                at least, as well Frima Treasure at Phase 3!
              </p>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: 2.5RL Scarlet Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
              </div>
              <p>
                Quantum Cube lv 7 on Jackal, Centi and Laplace. Basic 'Fast
                Nuke' team to save you a Clip RL (Anis or Emilia) for other
                teams. Laplace adds extra pressure on P1+P2 with her
              </p>
              <h5>Team #2: 3RL Emilia Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                    <NikkeCharacter mode="icon" slug="a2" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="laplace"
                      enablePopover
                      mode="icon-tier"
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Vigor Cube on Blanc. This team has a lot of hidden damage from
                Emilia + Laplace. A2 can add to the pressure on P1+P2 (and even
                P3). Noir will instead target P5. Blanc needs Vigor cube to
                survive the onslaught, however RedHood can use her B2 in case
                she dies, still allowing Laplace and Noir/A2 to deal decent
                damage to the opponent team.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Good Burst Generation (slightly below Clip SG)</li>
                        <li>
                          Hits hard, and will be even stronger if she can
                          pierce.
                        </li>
                        <li>
                          True Damage helps her a lot on high penalty stages and
                          in Raids.
                        </li>
                        <li>Her burst laser has pin point accuracy.</li>
                        <li>The Ark's Hero!</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          She requires a lot of investment, even more than top
                          meta DPS now to even work (Favorite Item System).
                        </li>
                        <li>
                          Her damage is only on okay level without pierce.
                        </li>
                        <li>
                          Her burst needs 5 full charge S1, which means you
                          mostly won't burst her first.
                        </li>
                        <li>
                          Can't cover during burst, literally Do or Die. Very
                          harsh quality to have for High Deficit Campaign.
                        </li>
                        <li>
                          Her burst's fire rate is affected by DPS, just like
                          any MG. Low FPS = Low DPS.
                        </li>
                        <li>Window Breaker...</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesLaplace;

export const Head: React.FC = () => (
  <Seo
    title="Laplace (Treasure) guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Laplace (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
